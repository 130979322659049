<template>
  <div class="contain">
    <div class="contain-header">
      <img
          :src="currentPersonnel.avatarUrl"
          alt="">
    </div>
    <div class="contain-title">参赛者: {{currentPersonnel.name}}</div>
    <div class="contain-about">
      参赛者简介: {{currentPersonnel.descContent}}
    </div>
    <div class="contain-form">
      <span style="width: 100px;font-weight: bold;font-size: 16px">基础分 :</span>
      <el-input disabled v-if="eventJudgeScore" v-model="eventInfo.scoreStandard===1?eventJudgeScore.scoreA:eventInfo.scoreStandard===2?eventJudgeScore.scoreB:eventJudgeScore.scoreC" placeholder="请输入基础分" size="small"></el-input>
      <el-input v-if="!eventJudgeScore" v-model="scoreObj.score" placeholder="请输入基础分" size="small"></el-input>
    </div>
    <div class="contain-form" v-if="eventInfo.scoreStandard===2">
      <span style="width: 100px;font-weight: bold;font-size: 16px">创新性 :</span>
      <el-input v-model="scoreObj.innovationScoreB" placeholder="请输入创新性" size="small"></el-input>
    </div>
    <div class="contain-form" v-if="eventInfo.scoreStandard===2">
      <span style="width: 100px;font-weight: bold;font-size: 16px">难度性 :</span>
      <el-input v-model="scoreObj.difficultyScoreB"  placeholder="请输入难度性" size="small"></el-input>
    </div>
    <div class="contain-form" v-if="eventInfo.scoreStandard===2">
      <span style="width: 100px;font-weight: bold;font-size: 16px">破纪录 :</span>
      <el-input v-model="scoreObj.recordScoreB" placeholder="请输入破纪录" size="small"></el-input>
    </div>
    <div class="contain-btn">
      <el-button :disabled="eventJudgeScore" @click="judgeScore" size="small" type="danger" style="width: 100px; background-color: #bf353b;color: #fff">提 交</el-button>
    </div>
  </div>
</template>

<script>
import {getScoreInfo,judgeScore} from "@/api/event";

export default {
  name: "score",
  data() {
    return {
      eventInfo: {},
      userInfo:{},
      currentPersonnel:{},
      eventJudgeScore:{},
      scoreObj:{
        score:null,
        innovationScoreB:null,
        difficultyScoreB:null,
        recordScoreB:null,
        personnelId:null,
        judgeId:null
      }
    }
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getScoreInfo(this.eventInfo.id,this.userInfo.id)
  },
  methods: {
    judgeScore(){
      this.$confirm('提交成绩, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.scoreObj.personnelId=this.currentPersonnel.id
        this.scoreObj.judgeId=this.userInfo.id
        const {data} = await judgeScore(this.scoreObj);
        if(data.code===200){
          await this.getScoreInfo(this.eventInfo.id,this.userInfo.id)
          this.$message.success('评分成功')
        }
      })
    },
    //查询评分信息
    async getScoreInfo(eventId,personnelId) {
      let obj = {
        personnelId:personnelId,
        eventId:eventId
      }
      const {data} = await getScoreInfo(obj)
      if (data.code === 200) {
          this.currentPersonnel = data.data.currentPersonnel;
        this.eventJudgeScore = data.data.eventJudgeScore;
      }
    }
  }
}
</script>

<style scoped lang="less">
.contain {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/scoreBanner.png") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .contain-header {
    width: 120px;
    height: 120px;
    margin: 20px 0;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contain-title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 20px;
  }

  .contain-about {
    margin: 20px 0;
    width: 50%;
    font-weight: bold;
    font-size: 16px;
    overflow: hidden;
  }

  .contain-form {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ .el-input__inner {
      border-radius: 0;
    }
  }

  .contain-btn {
    margin-top: 50px;
  }
}
</style>
